import React from 'react';
import OurValues from './OurValues';
import AClass from './AClass';
import Perks from './Perks';
import WorkersTest from './WorkersTest';

const CareerBody = () => {
  return (
    <div>
      <OurValues />
      {/* <AClass /> */}
      <Perks />
      {/* <WorkersTest /> */}
    </div>
  );
};

export default CareerBody;
