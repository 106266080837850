import React from 'react';
import c from '../image/cargos.svg';
import t from '../image/trucks.svg';
import d from '../image/Photo.png';
import cu from '../image/custom.svg';
import ll from '../image/landd.webp';
import cr from '../image/crane.svg';
import mee from '../image/hh.png';
import LocalCertSecond from './LocalCertSecond';
const International = () => {
  return (
    <div className="">
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted">
                <div className="inttt">
                  <span>
                  FREIGHT FORWARDING( AIR, SEA, LAND, MULTI-MODAL/IMPORT, EXPORT, DOOR TO DOOR AND LCL)
                  </span>
                </div>
                <div className="sofyun">
                  <span>
                  we organize the chain of transport through multi-modal 
                  transportation (sea, air, road or rail) to move cargo 
                  from its origin to the required destination as per client's incoterm.

                  we provide related services upon request: 
                  cargo insurance, stuffing/packing, unstuffing/unpacking, 
                  labelling, stripping, weighting....
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4 d-flex align-items-center">
              <div className="wellop">
                <img src={c} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="innerhouse bluup">
        <div className="container">
          <div className="row willeo">
            <div className="col-12 col-lg-6 mb-4">
              <div className="wellop">
                <img src={cu} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted2">
                <div className="inttt2">
                  <span>Custom clearance</span>
                </div>
                <div className="sofyun2">
                  <span>
                    Our Customs specialists manage all processes related to
                    customs clearance of cargo, vessel and aircraft in
                    accordance with applicable customs regulations in Cameroon
                    Pre-shipment coordination and overall paperwork review in
                    accordance with origin or destination, freight rules to
                    ensure smooth and cost-effective delivery up to requested
                    final destination - Process financial ad suspensive customs
                    regimes of cargo, vessel and aircraft - Accurate estimation
                    of duties and taxes to be paid - Payment of taxes on your
                    behalf, when necessary - Use of AMT Customs bond
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted3">
                <div className="inttt">
                  <span>
                    Land transport on national territory-hinterland (Central
                    Africa sub-region){' '}
                  </span>
                </div>
                <div className="sofyun">
                  <span>
                    We deliver shipments (Container, RORO, breakbulk, hazardous
                    cargo) in the CEMAC subregions such as Tchad, CAR, Congo,
                    Gabon, EG both on DDU(Delivered Duty Unpaid) and DDP(Delivery Duty Paid) basis
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="wellop">
                <img src={ll} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="innerhouse bluup">
        <div className="container">
          <div className="row willeo">
            <div className="col-12 col-lg-6 mb-4">
              <div className="wellop">
                <img src={t} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted2">
                <div className="inttt2">
                  <span>Logistics Base Management</span>
                </div>
                <div className="sofyun2">
                  <span>
                  Storage area rental and handling of cargo, 
                  according to international standards and 
                  requirements (quay, yard, warehouses). 
                  Delivery of cargo (product, equipment) 
                  from to airport/ port Storage/ inventory management: 
                  offload, load cargo and rental of storage area inside yard and warehouse; 
                  follow the entries and the exits to/from the storage
                   area and monitor the quality and the quantities 
                   of stored cargo trucks, cranes and forklifts management
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted">
                <div className="inttt">
                  <span>Shipping Agency</span>
                </div>
                <div className="sofyun">
                  <span>
                    <ul>
                      <li><strong>Marine Agency:</strong> Manage the administrative and logistics 
                      services for the floating unit 
                      (vessel, tanker, supply boat, surfer, barge, rig, FPSO) 
                      during its stay in national waters (port area or offshore).
                      </li>
                    </ul>
                    <ul>
                      <li>
                      <strong>Port Call:</strong> organize technical and administrative port call, 
                        including but not limited to, obtaining Inward and Outward 
                        clearances, visit of authorities and settlement of port 
                        taxes and dues relevant to the floating unit. 
                        Can also offer a wide range of services for the floating unit: 
                        ship chandler services, booking of berths, pilotage and 
                        tug assistance arrangement, work permits, shore passes.
                    </li>
                    </ul>
                    <ul>
                      <li>
                      <strong>Stevedoring:</strong> loading, unloading and stowage of cargo on board the floating unit
                      <ul style={{ listStyleType: 'circle' }}>
                          <li>
                          For Import: unload cargo from the floating unit’s hold, or deck, up to under ship’s tackle.
                          </li>
                          <li>
                          For Export: load cargo from under ship’s tackle up to floating unit’s deck or hold.
                          </li>
                          <li>
                          Transshipment: unload cargo from a floating unit and reload them into a different 
                          floating unit calling the same port.
                          </li>
                        </ul>   
                      </li>
                    </ul>
                    <ul>
                      <li>
                      <strong>Shore Handling:</strong> Handle on shore the cargo
                      <ul style={{ listStyleType: 'circle' }}>
                          <li>
                          For Import: receive quayside the cargo and organize its transfer from under 
                          ship’s tackle to the storage area in the port/terminal. 
                          </li>
                          <li>
                          For Export: organize cargo transfer from the port storage area up to under ship’s tackle. 
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul>
                      <li>
                      <strong>Shipping Line Management:</strong> including but not limited to freight collect, 
                        billing and collection of demurrage and detention charges, 
                        follow up of containers fleet, selling freight on behalf of the shipping line.
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4 d-flex align-items-center">
              <div className="wellop">
                <img src={d} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="innerhouse bluup">
        <div className="container">
          <div className="row willeo">
            <div className="col-12 col-lg-6 mb-4 d-flex align-items-center">
              <div className="wellop">
                <img src={cr} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted2">
                <div className="inttt2">
                  <span>Personnel Support</span>
                </div>
                <div className="sofyun2">
                  <span>
                  Assist the staff in his travel’s organization from the required 
                  departure location up to the destination including all 
                  compulsory formalities of the related countries. <br /> <br />
                    <ul>
                      <li> <strong>Formalities:</strong> Follow and comply with immigration formalities 
                        (visa, work permit, Letter of Invitation (LOI)). 
                        Obtain special passes allowing access within restricted areas.
                        </li>

                        <li> <strong>Travel Arrangements:</strong> Book and purchase hotel rooms, flight tickets. 
                        Rent vehicles, offices or accommodations.
                        </li>

                        <li> <strong>Welcome and Transport:</strong> Meet and greet at airport or port and arrange 
                        local transportation from the entry in the country, airport or port to a selected place.
                        </li>

                        <li> <strong>Local Transport:</strong> Arrange local transportation within the country
                        </li>

                        <li> <strong>Procurement for personnel:</strong> Provide local purchases, cash advance.
                        </li>
                    </ul>

                    {/* <ul>
                      <li>
                        {' '}
                        Immigration services- Visas/ work permits/ Letter Of
                        Invitation (LOI)
                      </li>
                    </ul>
                    <ul>
                      <li> Meet and greet services</li>
                    </ul>
                    <ul>
                      <li> Local transport/ transfers/ rentals</li>
                    </ul>
                    <ul>
                      <li>Personnel rotation management</li>
                    </ul>
                    <ul>
                      <li>Local purchasing</li>
                    </ul>
                    <ul>
                      <li>Medical assistance</li>
                    </ul>
                    <ul>
                      <li> Hand carry</li>
                    </ul>
                    <ul>
                      <li> Security services</li>
                    </ul> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted3">
                <div className="inttt">
                  <span>Air & Marine Chartering</span>
                </div>
                <div className="sofyun">
                  <span>
                  {/* <span><strong>Air & Marine Chartering</strong></span>
                  <br /><br /> */}
                    Customized Full and Part Chartering of Cargo Aircraft, 
                    Breakbulk, Heavy Lift & River Vessels, Barges, 
                    Tugs & Supply Boats around the world.
                    <br /> <br />
                    <ul>
                      <li>
                        Multi-modal transport solutions for cost economy,
                        ocean/air, road/air, rail/air
                      </li>
                    </ul>
                    <ul>
                      <li>
                        {' '}
                        Proposing different solutions depending on time lines,
                        also using co-load
                      </li>
                    </ul>
                    <ul>
                      <li> possibilities for cost economy</li>
                    </ul>
                    <ul>
                      <li> Advantage of space commitments</li>
                    </ul>
                    <ul>
                      <li>Barge transportation</li>
                    </ul>
                    <ul>
                      <li>Access to large variety of vessel types</li>
                    </ul>
                    <ul>
                      <li>Medical assistance</li>
                    </ul>
                    <ul>
                      <li>
                        Ocean carriage by multi-purpose, heavy lift and RoRo
                        vessels
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div
                className="wellop"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <img src={mee} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <LocalCertSecond />
    </div>
  );
};

export default International;
