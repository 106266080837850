import React, { useEffect, useState } from 'react';
import k from '../image/kard1.png'; // Placeholder image if needed
import { Link } from 'react-router-dom';
import { db } from '../firebase'; // Import your firebase configuration
import { doc, onSnapshot } from 'firebase/firestore';

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const userDocRef = doc(db, 'news', 'news');
    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setNews(userData['allNews'] || []);
      } else {
        setNews([]);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
  console.log(news);
  return (
    <div className="news_holder">
      <div className="news_and_update">
        <span>Latest News & Update</span>
      </div>

      <div className="mt-5">
        <div className="container">
          <div className="row">
            {news.slice(0, 3).map((item) => (
              <div  className="col-12 col-lg-4 mb-5">
                <Link
                  to={`/news-details/${item.id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="kard">
                    <div className="kard_image">
                      <img src={item.imageUrl || k} alt="" />
                    </div>
                    <div className="title_icon mt-3">
                      <div className="title_bissh">
                        <span>{item.title}</span>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 17L17 7M17 7H7M17 7V17"
                            stroke="#0A0A0A"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="lil_talk mt-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content.substring(0, 59),
                        }}
                      />
                    </div>
                  </div>



                </Link>
              </div>

              
            ))}
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default News;
